// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import {Application} from "@hotwired/stimulus";

const application = Application.start();

application.debug = false;
window.Stimulus = application;

import ApplicationController from "./application_controller";
application.register("application", ApplicationController);

import Clipboard from "./clipboard_controller";
application.register("clipboard", Clipboard);

import Dropdown from "./dropdown_controller";
application.register("dropdown", Dropdown);

import FacebooKTrackingCodes from "./facebook_tracking_codes";
application.register("facebook-tracking-codes", FacebooKTrackingCodes);

import FlashController from "./flash_controller";
application.register("flash", FlashController);

import TurboFlashController from "./turbo_flash_controller";
application.register("turbo-flash", TurboFlashController);

import FlashInController from "./animation/flash_in_controller";
application.register("flash-in", FlashInController);

import AutoSubmit from "./auto_submit_controller";
application.register("auto-submit", AutoSubmit);

import FormController from "./form_controller";
application.register("form", FormController);

import HubSpotTrackingCode from "./hubspot_tracking_code";
application.register("hubspot-tracking-code", HubSpotTrackingCode);

import IntersectionController from "./intersection_controller";
application.register("intersection", IntersectionController);

import MarqueeController from "./marquee_controller";
application.register("marquee", MarqueeController);

import MenuController from "./menu_controller";
application.register("menu", MenuController);

import NavController from "./nav_controller";
application.register("nav", NavController);

import NewModalController from "./new_modal_controller";
application.register("new-modal", NewModalController);

import FrequencySelectorController from "./billing/frequency_selector_controller";
application.register("billing--frequency-selector", FrequencySelectorController);